import {Controller} from "@hotwired/stimulus"
import {Modal} from "bootstrap"

let breakpoint = 1024

// Connects to data-controller="products--inquiry"
export default class extends Controller {
  static targets = ['flashContainer', 'headerContainer', 'modal', 'modalContent', 'titleText', 'btn', 'btnBottom', 'tabContainer']

  connect() {
    this.toggleBtn()
  }

  click(event) {
    if (userSignInState) return

    event.preventDefault()

    new Modal(this.modalTarget).show()
  }

  clickOpenUserSignInModal(event) {
    event.preventDefault()

    const that = this

    this.modalContentTarget.classList.add('loading')
    setTimeout(function() {
      that.modalContentTarget.classList.remove('loading')
      that.modalContentTarget.innerHTML = renderProductUserSignIn
    }, 300)
  }

  clickOpenUserSignUpModal(event) {
    event.preventDefault()

    const that = this

    this.modalContentTarget.classList.add('loading')
    setTimeout(function() {
      that.modalContentTarget.classList.remove('loading')
      that.modalContentTarget.innerHTML = renderProductUserSignUp
    }, 300)
  }

  closeModal(event) {
    event.preventDefault()

    Modal.getInstance(this.modalTarget).hide()
  }

  resizing() {
    this.toggleBtn()
  }

  scrolling() {
    this.toggleBtn()
  }

  toggleBtn() {
    if (!this.hasBtnTarget) return

    const
      windowTop = window.scrollY,
      flashHeight = this.hasFlashContainerTarget ? this.flashContainerTarget.clientHeight : 0,
      headerHeight = this.headerContainerTarget.clientHeight,
      btnTop = this.titleTextTarget.offsetTop,
      btnBottomHeight = this.btnBottomTarget.clientHeight * 2,
      tabContainerMarginBottom = window.innerWidth > breakpoint ? 64 : 48,
      tabContainerBottom =
        this.tabContainerTarget.offsetTop + this.tabContainerTarget.offsetHeight - tabContainerMarginBottom,

      isFixed = windowTop > btnTop - (flashHeight + headerHeight),
      isFixedTop = (this.tabContainerTarget.getBoundingClientRect().bottom - headerHeight) < 0,
      isStopped = (windowTop + window.innerHeight) > tabContainerBottom,
      btnBottomTop = isStopped ? `${tabContainerBottom - btnTop - btnBottomHeight}px` : null
    

    this.btnTarget.classList.toggle('fixed', isFixed)
    this.btnTarget.classList.toggle('fixed-top', isFixedTop)
    // this.btnTarget.classList.toggle('stopped', isStopped)
    // this.btnBottomTarget.style.top = btnBottomTop
  }
}
