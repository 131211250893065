import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="products--reviews"
export default class extends Controller {
  static targets = ['list', 'listContainer', 'noDataFound', 'menu', 'menuList', 'menuInnerList']

  connect() {
    this.events()
  }

  checkNoDataFound() {
    const that = this

    addEventListener('turbo:before-stream-render', function(event) {
      that.clearList()
      that.clearPreviousNoDataFound()
      that.clearPaginate()
    })
  }

  clearList() {
    if (!this.hasListTarget) return

    this.listTarget.childElementCount > 0 || (this.listContainerTarget.innerHTML = productReviewNoDataFoundHTML)
  }

  clearPreviousNoDataFound() {
    this.hasNoDataFoundTarget && this.noDataFoundTarget.remove()
  }

  clearPaginate() {
    const paginate = this.element.querySelector('.pagination')

    paginate && paginate.classList.add('disabled')
  }

  resizing() {
    this.close()
  }

  toggle() {
    this.menuTarget.classList.toggle('active')
    this.menuListTarget.style.height =
      this.menuTarget.classList.contains('active') ? `${this.menuInnerListTarget.clientHeight}px` : null
  }

  close() {
    this.menuTarget.classList.remove('active')
    this.menuListTarget.style.height = null
  }

  clickOutsideType() {
    const that = this

    document.addEventListener('click', function(event) {
      const containerTypeAround = that.menuTarget.contains(event.target),
        containerTypeVisible = window.getComputedStyle(that.menuTarget).visibility !== 'hidden',
        leftClicked = (event.which === 1)

      !containerTypeAround && containerTypeVisible && leftClicked && that.close()
    })
  }

  events() {
    if (!this.hasMenuTarget) return
    this.clickOutsideType()
  }
}
