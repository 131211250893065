import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="products--reviews--progress-bar"
export default class extends Controller {
  static targets = ['fiveStarRating', 'fourStarRating', 'threeStarRating', 'twoStarRating', 'oneStarRating']

  connect() {
    this.showProgressingRating()
  }

  progress(item) {
    item.style.width = `${item.dataset.value * 100}%`
  }

  showProgressingRating() {
    this.progress(this.fiveStarRatingTarget)
    this.progress(this.fourStarRatingTarget)
    this.progress(this.threeStarRatingTarget)
    this.progress(this.twoStarRatingTarget)
    this.progress(this.oneStarRatingTarget)
  }
}
