import {Controller} from "@hotwired/stimulus"
import {Modal} from "bootstrap"

// Connects to data-controller="products--reviews--modal-update"
export default class extends Controller {
  static targets = ['modal', 'menuItem']

  open(event) {
    event.preventDefault()

    new Modal(this.modalTarget).show()
  }

  hideMenu() {
    this.menuItemTarget.style.display = 'none'
  }

  closeDone(event) {
    event.preventDefault()

    Modal.getInstance(this.modalTarget).hide()
    this.element.classList.add('hide')
  }

  close(event) {
    event.preventDefault()

    Modal.getInstance(this.modalTarget).hide()
  }
}
