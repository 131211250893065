import { Controller } from "@hotwired/stimulus"
import {Modal} from 'bootstrap';

// Connects to data-controller="products--reviews--modal-delete"
export default class extends Controller {
  static targets = ['modal']

  open(event) {
    event.preventDefault()

    new Modal(this.modalTarget).show()
  }

  close(event) {
    event.preventDefault()

    Modal.getInstance(this.modalTarget).hide()
  }
}
